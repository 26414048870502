<template>
  <div class="w-full flex justify-between items-center p-2 pl-4 ">

    <NuxtLink class="h-full flex-shrink-0" to="/app" aria-label="Home">
        <button class="h-full flex justify-center items-center">
          <!-- <img class="w-16 flex-shrink-0" src="https://oxbull-presale.s3.amazonaws.com/oxb_green_header.png" /> -->
          <NuxtImg quality="50" width="64" class="w-16 flex-shrink-0 object-contain" src="/oxb_green_header.png" alt="Oxbull Logo" />

        </button>
      </NuxtLink>


      <div class="flex justify-end items-center w-full ">

        <SearchBar v-if="isMobile" />

      <WalletModal v-if="!$address">
        <button class="btn min-h-0 h-auto bg-turquoise-500 text-turquoise-1000 hover:text-turquoise-500 hover:bg-turquoise-1000 rounded-full p-3">Connect Wallet</button>
      </WalletModal>

      <div v-if="$address" class="bg-white/5 border border-white/10 rounded-full px-3 py-1 pr-1 flex justify-center items-center text-zinc-400">
        <div class="text-sm">{{ $address?.substring(0, 6) }}...{{ $address?.substring($address.length - 5) }}</div>

        <button @click="$deauth()" class="btn btn-circle bg-white/10 ml-2 btn-sm flex justify-center items-center rounded-full" aria-label="disconnect wallet">
          <Icon name="mdi:power" class="text-turquoise-500 text-xl" />
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
const route = useRoute();

import { useUserStore } from "~/stores/user.js";
const userStore = useUserStore();

import { OXB_V2_ADDRESS } from "~/wagmiConfig.js";
// import { getAccount, watchConnections } from "@wagmi/core";
import { queryNoCache } from "~/apollo";
import { gql } from "@apollo/client/core";
import { useClipboard } from "@vueuse/core";

const { text, copy, copied, isSupported } = useClipboard({ source: OXB_V2_ADDRESS });

import { useEventBus } from "@vueuse/core";
const bus = useEventBus("reload");

const { $accessToken, $address, $deauth, $auth } = useNuxtApp();

import { useScreens } from "vue-screen-utils";

const { mapCurrent } = useScreens({
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
});

const isMobile = mapCurrent({
  xs: true,
  sm: true,
  md: true,
  lg: false,
});

const reauth = async () => {
  // await $deauth();
  // await $auth();
};

onMounted(() => {
  watch($accessToken, () => {
    renderHeader();
  });
});

const address = ref("");

const showRefresh = ref(false);

// const toggleRefresh = (ev) => {
//   showRefresh.value = ev;
// };

// const refreshPage = (close) => {
//   close();
//   console.log(bus);
//   bus.emit("reload", true);
// };

const accountData = ref({});

const renderHeader = async () => {
  // accountData.value = null;
  // if (userStore.authenticated) {
  //   let gqlResult = await queryNoCache(
  //     gql`
  //       query User($limit: Int, $after: Int) {
  //         user(limit: $limit, after: $after) {
  //           tiers
  //         }
  //       }
  //     `,
  //     {
  //       limit: 1,
  //       after: null,
  //     }
  //   );

  //   accountData.value = gqlResult["user"];
  // }
};

const navItems = [
  // {
  //   name: "home",
  //   path: "/app",
  //   icon: "entypo:home",
  //   title: "Home",
  // },
  {
    name: "account",
    path: "/app/account",
    icon: "fa-solid:user-circle",
    title: "Account",
  },
  {
    name: "claim",
    path: "/app/claim",
    icon: "mdi:coins-plus",
    title: "Claim",
  },
  {
    name: "stake",
    path: "/app/stake",
    icon: "bx:bxs-bank",
    title: "Stake",
  },
  {
    name: "calendar",
    path: "/app/calendar",
    icon: "mdi:calendar",
    title: "Calendar",
  },
  {
    name: "policy",
    path: "/app/policy",
    icon: "ic:baseline-policy",
    title: "Tiers & Policy",
  },

  {
    name: "privateSalePass",
    path: "/app/private-sale-pass",
    icon: "mdi:passport",
    title: "Private Sale Pass",
  },

  {
    name: "otc",
    path: "/app/otc",
    icon: "ant-design:shop-filled",
    title: "OTC",
  },
];

const pinnedNav = computed(() => {
  let _navItems = [...navItems];
  return _navItems.splice(0, 4);
});

// const nonPinnedNav = computed(() => {
//   let _navItems = [...navItems];
//   return _navItems.splice(4);
// });

// const currentTitle = computed( () => {
//   return navItems.find((item) => item.name === route.name).title;
// })

// const getBadge = computed(() => {
//   switch (accountData.value?.tiers) {
//     case "BSC_T1":
//       return "https://oxbull-presale.s3.amazonaws.com/tier1.svg";

//     case "BSC_T2":
//       return "https://oxbull-presale.s3.amazonaws.com/tier2.svg";

//     case "BSC_T3":
//       return "https://oxbull-presale.s3.amazonaws.com/tier3.svg";

//     default:
//       return false;
//   }
// });

// console.log(route);

// onUnmounted(() => {
//   unwatch();
// });
</script>

<style>

</style>